'use strict';

/* 
****************************************************
MOBILE NAVIGATION
****************************************************
*/
const mobileMenuBtn = document.getElementById('mobile-menu-btn');
const mobileMenu = document.getElementById('mobile-menu');
const mobileMenuCloseBtn = document.getElementById(
  'mobile-menu-close-btn-container'
);
const mobileMenuList = document.querySelector('.mobile-menu-nav-list');

const toggleMobileMenu = function () {
  popup.setAttribute('data-active', 'false');
  overlay.setAttribute('data-active', 'false');
  mobileMenu.classList.toggle('active');
  document.documentElement.classList.toggle('no-scroll');
};

mobileMenuBtn.addEventListener('click', toggleMobileMenu);

mobileMenuCloseBtn.addEventListener('click', toggleMobileMenu);

document.addEventListener('keydown', function (e) {
  if (e.key === 'Escape') {
    mobileMenu.classList.remove('active');
    document.documentElement.classList.remove('no-scroll');
  }
});

mobileMenuList.addEventListener('click', function (e) {
  if (e.target.classList.contains('mobile-menu-nav-link')) {
    toggleMobileMenu();
    scrollToSection(e);
  }
});

/* 
****************************************************
SHUTTLE SCHEDULE POPUP
****************************************************
*/

const popup = document.querySelector('.popup');
const overlay = document.querySelector('.overlay');
const popupBtn = document.querySelector('.test-bar');
const popupCloseBtn = document.querySelector('.popup-closeBtn');
const banner = document.querySelector('.banner');
const navBtn = document.querySelector('.main-nav-rsvp');
const faqScheduleLink = document.querySelector('.faq-schedule-link');

const togglePopup = function () {
  const isCurrentlyActive = popup.getAttribute('data-active') === 'true';
  const newState = (!isCurrentlyActive).toString();
  popup.setAttribute('data-active', newState);
  overlay.setAttribute('data-active', newState);
};

popupBtn.addEventListener('click', togglePopup);
popupCloseBtn.addEventListener('click', togglePopup);
overlay.addEventListener('click', togglePopup);
banner.addEventListener('click', togglePopup);
navBtn.addEventListener('click', togglePopup);
faqScheduleLink.addEventListener('click', togglePopup);

document.addEventListener('keydown', function (event) {
  if (event.key === 'Escape' && popup.getAttribute('data-active') === 'true') {
    togglePopup();
  }
});

/* 
****************************************************
ANIMATION - SMOOTH SCROLLING
****************************************************
*/
const scrollToSection = function (e) {
  const id = e.target.getAttribute('href');
  document.querySelector(id).scrollIntoView({ behavior: 'smooth' });
};
document
  .querySelector('.main-nav-list')
  .addEventListener('click', function (e) {
    if (e.target.classList.contains('main-nav-link')) {
      const id = e.target.getAttribute('href');
      document.querySelector(id).scrollIntoView({ behavior: 'smooth' });
    }
  });

/* 
****************************************************
ANIMATION - HIGHLIGHT CURRENT SECTION IN NAVBAR
****************************************************
*/

const sections = document.querySelectorAll('.section');
const navLinks = document.querySelectorAll('.main-nav-link');
const header = document.getElementById('header');
let lastScrollY = window.scrollY;

const setCurrentSection = (section) => {
  navLinks.forEach((link) => link.classList.remove('highlight'));
  if (section.id === 'hero' || section.id === 'footer') return;
  const activeLink = document.getElementById(
    `link-section--${section.id.slice(-1)}`
  );
  activeLink.classList.add('highlight');
};

const highlightCurrentNav = function () {
  const headerHeight = header.offsetHeight;
  const scrollY = window.scrollY + headerHeight;
  const direction = scrollY > lastScrollY ? 'down' : 'up';
  lastScrollY = scrollY;
  const viewportHeight = window.innerHeight;
  const footerTop = document.querySelector('.footer').offsetTop;

  sections.forEach((section, index) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.offsetHeight;

    // Determine if the scroll position is in this section
    const sectionBottom = sectionTop + sectionHeight;
    const nextSection = sections[index + 1];

    // Highlight based on scroll direction and whether we're in the current section
    if (
      direction === 'down' &&
      scrollY >= sectionTop &&
      scrollY < sectionBottom
    ) {
      setCurrentSection(section);
    } else if (
      direction === 'up' &&
      nextSection &&
      scrollY >= sectionTop - 1 &&
      scrollY < sectionBottom
    ) {
      setCurrentSection(section);
    } else if (scrollY + viewportHeight > footerTop) {
      setCurrentSection(section);
    }
  });
};

window.addEventListener('scroll', highlightCurrentNav);
highlightCurrentNav();

/* 
****************************************************
ANIMATION - OUR STORY COVER
****************************************************
*/

const animateOurStoryCover = function () {
  const storyCoverSection = document.querySelector('.story-cover-container');
  const storyCoverClasses = document.querySelectorAll(
    '.story-cover-heading, .story-cover-subheading, .story-cover-gallery-left, .story-cover-gallery-middle, .story-cover-gallery-right'
  );

  const ourStoryCoverObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          storyCoverClasses.forEach((element) =>
            element.classList.add('slide-in')
          );
        }
      });
    },
    { threshold: 0.2 }
  );
  ourStoryCoverObserver.observe(storyCoverSection);
};

animateOurStoryCover();

/* 
****************************************************
ANIMATION - OUR STORY TIMELINE
****************************************************
*/

const revealElement = function (entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('slide-from-bottom');
    }
  });
};
const ourStoryObserver = new IntersectionObserver(revealElement, {
  rootMargin: '5% 0px',
});

const timelineElements = [
  ...document.querySelectorAll(
    '.story-timeline-img-box, .story-timeline-content, .story-timeline-spacer'
  ),
];
timelineElements.forEach((element) => {
  ourStoryObserver.observe(element);
});

/* 
****************************************************
ANIMATION - PLANES
****************************************************
*/
const travel = document.querySelector('.travel');
const planes = document.querySelector('.airplanes-container');

const airplanesObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      planes.classList.toggle('animate', entry.isIntersecting);
      if (entry.isIntersecting) airplanesObserver.unobserve(entry.target);
    });
  },
  { threshold: 0.75 }
);

airplanesObserver.observe(travel);

/* 
****************************************************
ANIMATION - ACCORDION MENU
****************************************************
*/

document.addEventListener('DOMContentLoaded', function () {
  const items = document.querySelectorAll('.faq-item');
  items.forEach((item) => {
    const title = item.querySelector('.faq-item-title');
    const content = item.querySelector('.faq-item-content');
    const chevron = title.querySelector('.chevron');
    title.addEventListener('click', () => {
      title.classList.toggle('active');
      content.classList.toggle('active');
      chevron.classList.toggle('rotate');
    });
  });
});

/* 
****************************************************
COUNTDOWN TIMER
****************************************************
*/

const timerDays = document.querySelector('.timer-day');
const timerHours = document.querySelector('.timer-hour');
const timerMinutes = document.querySelector('.timer-minute');
const timerSeconds = document.querySelector('.timer-second');
const weddingDate = new Date(2024, 8, 1);

const calcTimeRemaining = function () {
  const nowDate = new Date();
  const timeRemaining = weddingDate - nowDate;
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);
  timerDays.textContent = `${days === 1 ? `${days} day` : `${days} days`}`;
  timerHours.textContent = `${
    hours === 1 ? `${hours} hour` : `${hours} hours`
  }`;
  timerMinutes.textContent = `${
    minutes === 1 ? `${minutes} minute` : `${minutes} minutes`
  }`;
  timerSeconds.textContent = `${
    seconds === 1 ? `${seconds} second` : `${seconds} seconds`
  }`;
};

const countdownTimer = setInterval(() => {
  calcTimeRemaining();
}, 1000);
